const TarifsEtHoraires = () => {
    return (
        <main className="flex flex-col items-center min-h-[calc(100vh-82px-40px)] justify-evenly mt-20 p-1 md:p-4">
                <h1 className="font-petitFormalScript text-3xl text-center font-semibold my-6 md:text-4xl">
                    Tarifs et Horaires
                </h1>
                <div className="font-poppins text-lg mx-4 flex flex-col ">
                    
                    <p className="mt-6 mx-4 md:text-xl">
                        Le cabinet est ouvert du lundi au samedi de 9 h à 12 h puis de 14 h à 19 h.
                    </p>

                    <p className="mt-6 mx-4 md:text-xl">
                        Les consultations se font sur rendez-vous.
                    </p>
                    <p className="mx-4 md:text-xl">
                        Merci de prévenir en cas d'impossibilité d'honorer un RDV.
                    </p>

                    <p className="font-poppins text-lg my-6 mx-4 md:text-xl">
                        Moyen de paiement : chèques ou espèces.
                    </p>

                    <section className="font-poppins text-lg rounded-md bg-pink-900/[.14] p-4 mb-4 md:text-xl">
                        <h2 className="text-center font-medium text-2xl">Réflexologie</h2>
                        <div className="flex flex-col justify-evenly text-center md:flex-row">
                            <article className="flex flex-col justify-around rounded-md bg-pink-900/[.14] m-2 p-2 md:w-1/3">
                                <div>
                                    <h3 className="font-bold mb-2 md:text-xl">Bébés</h3>
                                    <p className="text-sm mb-6">(- 3 ans)</p>
                                </div>
                                <p>séance de 15 min*</p>
                                <p className="text-2xl mt-4 font-medium">15€</p>
                            </article>
                            <article className="flex flex-col justify-around rounded-md bg-pink-900/[.14] m-2 p-2  md:w-1/3">
                                <div>
                                    <h3 className="font-bold mb-2 md:text-xl">Enfants</h3>
                                    <p className="text-sm mb-6">(3 - 10 ans)</p>
                                </div>
                                <p>séance de 30 min*</p>
                                <p className="text-2xl mt-4 font-medium">30€</p>
                            </article>
                            <article className="flex flex-col justify-around rounded-md bg-pink-900/[.14] m-2 p-2 md:w-1/3">
                                <div>
                                    <h3 className="font-bold mb-2 break-all md:text-xl">Adultes et adolescents</h3>
                                    <p className="text-sm mb-6">(+ 10 ans)</p>
                                </div>
                                <p>séance de 60 min*</p>
                                <p className="text-2xl mt-4 font-medium">60€</p>
                            </article>
                        </div>
                        <p className="text-sm mt-1">*Le temps de séance ci-dessus ne comprend pas l'entretien préalable.</p>
                    </section>

                    <section className="font-poppins text-lg rounded-md bg-pink-900/[.14] p-4 mb-4 md:text-xl">
                        <h2 className="text-center font-medium text-2xl">Magnétisme</h2>
                        <div className="flex flex-col justify-evenly text-center md:flex-row">
                            <article  className="flex flex-col justify-around rounded-md bg-pink-900/[.14] m-2 p-2 md:w-1/3">
                                <div>
                                    <h3 className="font-bold mb-2 md:text-xl">Bébés</h3>
                                    <p className="text-sm mb-6">(- 3 ans)</p>
                                </div>
                                <p>séance de 15 min*</p>
                                <p className="text-2xl mt-4 font-medium">10€</p>
                            </article>
                            <article className="flex flex-col justify-around rounded-md bg-pink-900/[.14] m-2 p-2 md:w-1/3">
                                <div>
                                    <h3 className="font-bold mb-2 md:text-xl">Enfants</h3>
                                    <p className="text-sm mb-6">(3 - 10 ans)</p>
                                </div>
                                <p>séance de 20 min*</p>
                                <p className="text-2xl mt-4 font-medium">20€</p>
                            </article>
                            <article className="flex flex-col justify-around rounded-md bg-pink-900/[.14] m-2 p-2 md:w-1/3">
                                <div>
                                    <h3 className="font-bold mb-2 break-all md:text-xl">Adultes et adolescents</h3>
                                    <p className="text-sm mb-6">(+ 10 ans)</p>
                                </div>
                                <p>séance de 40 min*</p>
                                <p className="text-2xl mt-4 font-medium">50€</p>
                            </article>
                        </div>
                        <p className="text-sm mt-1">*Le temps de séance ci-dessus ne comprend pas l'entretien préalable.</p>
                    </section>
                    <section  className="font-poppins text-lg rounded-md bg-pink-900/[.14] p-4 md:text-xl">
                        <h2 className="text-center font-medium text-2xl">Radiesthésie</h2>
                        <p className="font-medium mt-4 md:text-xl">Merci de me contacter pour connaître le tarif.</p>
                    </section>
                    
                    <p className="font-poppins text-xl mt-14 mb-6 mx-4 md:text-xl">
                        <u>Attention</u>: Le soin par magnétisme ne se substitue pas à un suivi médical, c'est un complément.
                    </p>
                </div>
        </main>
    )
};


export default TarifsEtHoraires;