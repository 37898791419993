import Accueil from '../Accueil';
import APropos from '../APropos';
import LesSoins from '../LesSoins';
import TarifsEtHoraires from '../TarifsEtHoraires';
import Contact from '../Contact';
import Page404 from '../Page404';
import Header from '../Header';
import Footer from '../Footer';

import { Routes, Route } from 'react-router-dom';

const App = () => {
    return (
  
        <div className=" bg-custom-pinkgrey text-black">
            <Header />

            <Routes>
    
                <Route path='/' exact element={<Accueil />} />

                <Route path='/apropos' exact element={<APropos />} />
                <Route path='/lessoins' exact element={<LesSoins />} />
                <Route path='/tarifsethoraires' exact element={<TarifsEtHoraires />} />
                <Route path='/contact' exact element={<Contact />} />

                <Route path='*' element={<Page404 />} />
    
            </Routes>

            <Footer />
    
        </div>
      )
};


export default App;