import photoApropos from "../../images/img_apropos.jpg"

const APropos = () => {
    
    return (
        <main className="flex flex-col items-center min-h-[calc(100vh-82px-40px)] justify-evenly mt-20 p-1 md:p-4">
            <h1
            className="font-petitFormalScript text-3xl text-center font-semibold my-6 md:text-4xl"
            >
                A Propos
            </h1>
            <div className="flex flex-col items-center lg:flex-row lg:justify-evenly">
                <img src={photoApropos} alt="réfléxologie sur pied d'enfant" className="w-3/4 my-6 md:max-w-lg md:w-1/2"/>
                    <div className="md:w-1/2">
                        <p className="font-poppins text-center text-lg my-10 mx-4 md:text-xl">
                            Je m'appelle Martine, je suis magnétiseuse depuis 30 ans. J'ai reçu ce don en héritage de ma mère et mon grand-père.
                        </p>
                        <p className="font-poppins text-center text-lg my-10 mx-4 md:text-xl">
                            Je me suis formée en 2003 au Reiki puis à la radiesthésie en 2005 afin d'élargir mon champ de compétences.
                        </p>
                        <p className="font-poppins text-center text-lg my-10 mx-4 md:text-xl">
                            Après ma formation sur la réflexologie plantaire en 2013 et 2014,  j’ai participé à une formation d’haptologie (dont une partie était sur les huiles essentielles) toujours dans le but de mieux vous accompagner.
                        </p>
                        <p className="font-poppins text-center text-lg my-10 mx-4 md:text-xl">
                            <a href="tel:+33677451797" className="hover:underline hover:decoration-2 hover:underline-offset-4">Contactez-moi</a>, je me ferai un plaisir de vous accompagner dans la résolution de vos maux.
                        </p>
                    </div>
            </div>


        </main>
    )
};


export default APropos;