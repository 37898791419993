import photoAccueil from "../../images/img_accueil.jpg";

import { Link } from "react-router-dom";

const Accueil = () => {
    
    return (
    
        <main className="flex flex-col items-center min-h-[calc(100vh-82px-40px)] justify-evenly mt-20 p-1 md:p-4">
            
            <h1
            className="font-petitFormalScript text-4xl text-center font-semibold my-6 md:text-5xl"
            >
                <Link
                to="/apropos"
                className="cursor-pointer hover:underline hover:decoration-2 hover:underline-offset-4"
                >
                    Martine Angibaud
                </Link>
            </h1>

            <div className="flex flex-col justify-evenly items-center md:flex-row">
                <img
                src={photoAccueil}
                alt="pierres et huiles essentielles"
                className="w-3/4 m-6 md:max-w-sm"
                />
            
                <h2 
                className="font-cormorantInfant font-bold text-center text-4xl m-4 md:text-4xl md:w-1/2"
                >
                    Magnétiseuse, réflexologue plantaire et radiesthésiste
                </h2>
            </div>
        </main>
    )
};


export default Accueil;