const Footer = () => {
    return (
  
        <footer className="bg-custom-pinkgrey font-cormorantInfant h-10 p-2 text-xs text-center shadow-inner shadow-custom-pink sm:text-sm md:text-base">
    
            <p>@2022 par <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/morganebenureau/">Morgane Benureau</a> - tous droits réservés</p>
    
        </footer>
      )
};


export default Footer;