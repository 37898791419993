import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from "leaflet";
import 'leaflet/dist/leaflet.css';

import logo from "../../images/Logo_fond_transparent.png";
import iconMarker from "../../images/icon_marker_logo.png";

const Contact = () => {
    const position = [47.65185546875, -1.3132485151290894];
    const customMarker = new L.icon({iconUrl: iconMarker, iconSize: [40,40]});

    return (
        <main className="flex flex-col items-center min-h-[calc(100vh-82px-40px)] justify-evenly mt-20 p-1 md:p-4">
                <h1 className="font-petitFormalScript text-3xl text-center font-semibold my-6 md:text-4xl">
                    Contact
                </h1>

                <h2 className="font-petitFormalScript text-xl text-center font-medium my-6 md:text-3xl">Située entre Chateaubriant et Ancenis, n'hésitez pas à me contacter.</h2>
                
                <div className="flex flex-col items-center lg:flex-row lg:justify-between">
                    <img 
                    src={logo} 
                    alt=""
                    className="w-3/4 my-6 md:max-w-sm md:mr-48"
                    />
                    <div>
                        <p className="font-poppins text-lg mt-6 mx-4 md:text-xl">
                        Mme Angibaud Martine <br/>
                        5 rue de la garenne<br/>
                        44110 Erbray
                        </p>
                        <div>
                            <MapContainer center={position} zoom={13} scrollWheelZoom={true} className="h-44 mt-6 mx-4 z-0">
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={position} icon={customMarker}>
                                <Popup>
                                Mme Angibaud Martine <br/>
                                5 rue de la garenne<br/>
                                44110 Erbray
                                </Popup>
                            </Marker>
                            </MapContainer>
                        </div>

                        <p className="font-poppins text-lg mt-6 mx-4 md:text-xl"> tel:
                        <a href="tel:+33677451797"> 06 77 45 17 97 </a>
                        </p>
                        <p className="font-poppins text-lg my-6 mx-4 md:text-xl"> mail:
                        <a href="mailto:martine.angibaud9@gmail.com"> martine.angibaud9@gmail.com</a>
                        </p>
                    </div>
                </div>
        </main>
    )
};


export default Contact;