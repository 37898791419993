const LesSoins = () => {
    return (
        <main className="flex flex-col items-center min-h-[calc(100vh-82px-40px)] justify-evenly mt-20 p-1 md:p-4">
            <h1
            className="font-petitFormalScript text-3xl text-center font-semibold my-6 md:text-4xl"
            >
                Les Soins
            </h1>
            <div className="flex flex-col items-center lg:flex-row lg:justify-evenly">
                <div className="font-poppins text-lg md:ml-2 md:w-1/2">
                    <p className="mt-6 mx-4 md:text-xl">
                    Le <b>magnétisme</b> sert à soulager les maux du corps qu’ils soient physiques comme :
                    </p>
                    <ul className="list-['-'] ml-10">
                        <li>des douleurs</li>
                        <li>des brûlures (coups de soleil, inflammations, rougeurs...)</li>
                        <li>le zona</li>
                        <li>des allergies/intolérances</li>
                        <li>des infections</li>
                        <li>des plaies/fractures</li>
                        <li>diverses maladies</li>
                        <li>...</li>
                    </ul>
                    <p className="mt-6 mx-4 md:text-xl">
                    ou psychiques comme : 
                    </p>
                    <ul className="list-['-'] ml-10 mb-10">
                        <li>les addictions</li>
                        <li>les émotions (déprime, dépression, stress, choc émotionnel, ...)</li>
                        <li>le burn out</li>
                        <li>les troubles du sommeil</li>
                        <li>les troubles de l'attention et de l'hyperactivité</li>
                        <li>...</li>
                    </ul>
                </div>
                <div className="font-poppins text-lg mx-4 md:w-1/2 md:text-xl">
                    <p className="mb-14">
                        La <b>réflexologie plantaire</b> réharmonise le corps via des pressions légères sur des zones précises, chacune reliée à des organes du corps ou une partie du corps.
                    </p>
                    <p className="mt-14">
                        La <b>radiesthésie</b> m’aide à purifier et à harmoniser l’énergie des lieux de vie. J’interviens dans le but de neutraliser les nuisances affectant les lieux de vie (sources d’eaux souterraines, failles géologiques, ondes magnétiques et électriques, etc…) ainsi que les entités et mémoires présentes.
                        
                    </p>
                    <p>
                        Elle me sert également dans la pratique du pendule afin de mieux cibler les soins à vous apporter.
                    </p>
                    <p className=" my-14">
                        Pour plus d’informations, n’hésitez pas à me&nbsp;
                        <a 
                            href="tel:+33677451797" 
                            className="cursor-pointer hover:underline hover:decoration-2 hover:underline-offset-4">
                                contacter
                        </a>
                        .
                    </p>
                </div>
            </div>


        </main>
    )
};


export default LesSoins;